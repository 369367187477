import { render, staticRenderFns } from "./HomeInformation.vue?vue&type=template&id=22aa3bca&scoped=true&"
import script from "./HomeInformation.vue?vue&type=script&lang=ts&"
export * from "./HomeInformation.vue?vue&type=script&lang=ts&"
import style0 from "./HomeInformation.vue?vue&type=style&index=0&id=22aa3bca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22aa3bca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionTitle: require('/codebuild/output/src2096755600/src/threease_lp/components/shared/Base/BaseSectionTitle.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src2096755600/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
